import React, { useState } from "react";
import {
  TextField,
  Button,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
  Box,
} from "@mui/material";
import Eye from '@mui/icons-material/Visibility';
import EyeSlash from '@mui/icons-material/VisibilityOff';

const RegisterForm = ({ onSignup }) => {
  const [formData, setFormData] = useState({ name: "", email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setSubmissionError("");

    if (validateForm()) {
      // Simulate API submission 
      onSignup(formData);
      console.log("Form submitted:", formData);
    } else {
      setSubmissionError("Please fix the errors above before submitting.");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '90%', sm: 400 },
        margin: "auto",
        padding: 4,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        boxShadow: 3,
        borderRadius: 4,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgb(0, 0, 0)",
      }}
    >
      <Typography variant="h5" textAlign="center" mb={2}>
        Sign Up
      </Typography>

      {submissionError && <Alert severity="error">{submissionError}</Alert>}

      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        error={!!errors.name}
        helperText={errors.name}
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black', },
          '& .MuiInputLabel-root.Mui-focused': { color: 'black', },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': { color: 'black', },
        }}
      />

      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        error={!!errors.email}
        helperText={errors.email}
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black', },
          '& .MuiInputLabel-root.Mui-focused': { color: 'black', },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': { color: 'black', },
        }}
      />

      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        name="password"
        type={showPassword ? "text" : "password"}
        value={formData.password}
        onChange={handleInputChange}
        error={!!errors.password}
        helperText={errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Eye /> : <EyeSlash />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black', },
          '& .MuiInputLabel-root.Mui-focused': { color: 'black', },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': { color: 'black', },
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ backgroundColor: "rgb(0, 0, 0)", mt: 2 }}
      >
        Sign Up
      </Button>
    </Box>
  );
};

export default RegisterForm;
