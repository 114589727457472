import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Facebook from "./Facebook";
import Comments from "./Comments";
import "./SaveBlog.css";
import axios from "./utils/axios";
import { useTokens } from "./TokenContext";

export function SaveBlog() {
  const { userInfo } = useTokens();
  const userId = userInfo?.id;
  const { isLoggedIn } = userInfo;
  const { id } = useParams();
  const navigate = useNavigate();

  const [contentBeforeHeading, setContentBeforeHeading] = useState("");
  const [contentAfterHeading, setContentAfterHeading] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [publishDate, setPublishDate] = useState("Date not available");
  const [liked, setLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0); // Number of likes
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/blog/getblog/${id}`);
        if (response.data.blog && response.data.blog.content) {
          const {
            content,
            userName,
            profileImageUrl,
            createdAt,
            comments,
            likes,
            totalLikes, // Assuming totalLikes is returned
          } = response.data.blog;

          setPublishDate(createdAt.split("T")[0]);
          setUserName(userName || "nishkarsh");
          setProfileImage(profileImageUrl || "default-profile.png");
          setComments(comments || []);
          setLiked(likes.includes(userId)); // Check if the user already liked the blog
          setTotalLikes(totalLikes || 0); // Set the total likes

          const firstHeadingRegex = /(<h[1-6].*?>.*?<\/h[1-6]>)/i;
          const parts = content.split(firstHeadingRegex);

          if (parts.length > 1) {
            setContentBeforeHeading(parts[0] + parts[1]);
            setContentAfterHeading(parts.slice(2).join(""));
          } else {
            setContentBeforeHeading(content);
          }
        } else {
          navigate("/home");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Failed to fetch blog content");
        navigate("/home");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id, navigate, userId]);

  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl);
    alert("Blog URL copied to clipboard!");
  };

  const handleLike = async () => {
    if (!isLoggedIn) navigate("/login");
    try {
      setLiked((prevLiked) => !prevLiked);

      const response = await axios.post(`/blog/like/${id}`, { userId });
      console.log("Response:", response.data.message);

      // Update total likes
      setTotalLikes(response.data.totalLikes);
    } catch (error) {
      console.error("Error liking/unliking the blog:", error);
      setLiked((prevLiked) => !prevLiked); // Revert in case of error
    }
  };

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="blog-content">
      <div dangerouslySetInnerHTML={{ __html: contentBeforeHeading }} />
      <hr />
      <div className="author-info">
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            alt={userName}
            src={profileImage}
            style={{ backgroundColor: "#3f51b5" }}
          />
          <p className="author-name">
            <strong>{userName}</strong>
          </p>
        </Stack>
        <p className="publish-date">{publishDate}</p>
        <div className="button-container">
          <Button
            variant="text"
            startIcon={<ShareIcon style={{ color: "#3f51b5" }} />}
            onClick={handleShare}
            className="sharebutton"
          />
          <Button
            variant="text"
            startIcon={
              liked ? (
                <FavoriteIcon style={{ color: "#f50057" }} />
              ) : (
                <FavoriteBorderIcon style={{ color: "#f50057" }} />
              )
            }
            onClick={handleLike}
            className="likebutton"
          />
          
        </div>
        <div className="like-count">
            <p>{totalLikes} {totalLikes === 1 ? "Like" : "Likes"}</p>
          </div>
      </div>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: contentAfterHeading }} />
      <Comments blogId={id} comments={comments} />
      <Facebook />
    </div>
  );
}

export default SaveBlog;
