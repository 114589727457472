import React from 'react'
import ArcDesign from './ArcDesign'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'

const AdvancedResultCard = ({ question, url, index, currentIndex, data, reference, swiped, name }) => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const x = useMotionValue(0);

    const opacity = useTransform(x, [-300, 0, 300], [0, 1, 0]);
    const opacityInMobile = useTransform(x, [-100, 0, 100], [0, 1, 0]);
    const rotate = useTransform(x, [-300, -50, 50, 300], [-15, 0, 0, 15]);
    const rotateInMobile = useTransform(x, [-100, 0, 100], [-15, 0, 15]);
    const handleNextClick = () => {
        if (index % 2 === 0) {
            if (isMobile) {
                animate(x, -100, { duration: 0.15 }).then(() => {
                    swiped('left', name, index);
                });
            } else {
                animate(x, -300, { duration: 0.35 }).then(() => {
                    swiped('left', name, index);
                });
            }
        } else {
            if (isMobile) {
                animate(x, 100, { duration: 0.15 }).then(() => {
                    swiped('right', name, index);
                });
            } else {
                animate(x, 300, { duration: 0.35 }).then(() => {
                    swiped('right', name, index);
                });
            }
        }
    }

    return (
        <motion.div
            reference={reference}
            style={{
                gridRow: 1,
                gridColumn: 1,
                opacity: isMobile ? opacityInMobile : opacity,
                rotate: isMobile ? rotateInMobile : rotate,
                x: x,
            }}
        >
            <div
                style={{
                    backgroundImage: `linear-gradient(to right, #888888, #eeeeee), url(${url})`,
                }}
                className="card"
            >
                <div className="question">
                    <p>{question}</p>
                </div>
                <button
                    className="pressable"
                    onClick={handleNextClick}
                    style={{
                        backgroundColor: 'gray',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '20px',
                        fontFamily: 'Black Ops One',
                        fontSize: '16px', // Adjust the font size as needed
                    }}
                >
                    Next
                </button>

                {index === currentIndex && (
                    <ArcDesign
                        data={data || []}
                        className="arc-container"
                    />
                )}
            </div>
        </motion.div>
    )
}

export default AdvancedResultCard