import React, { useState, useMemo, useRef, useEffect } from 'react'
import TinderCard from 'react-tinder-card'
import SignInwithGoogle from '../../../SignInwithGoogle'
import { toast, ToastContainer } from 'react-toastify'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../../LoginFirebase'
import { dbms } from '../../../LoginFirebase'
import { useNavigate, useLocation } from 'react-router-dom'
import SwipeCard from './SwipeCard'

// Array of questions with corresponding images
const dbs = [
  {
    id: 0,
    name: 'Question1',
    url: './img/richard.jpg',
    question:
      'Do you believe AI can ever achieve true moral and ethical reasoning?',
  },
  {
    id: 1,
    name: 'Question2',
    url: './img/erlich.jpg',
    question: 'Is it ethical to use AI in military applications?',
  },
  {
    id: 2,
    name: 'Question3',
    url: './img/monica.jpg',
    question: 'Can AI be trusted to act in the best interest of humanity?',
  },
  {
    id: 3,
    name: 'Question4',
    url: './img/jared.jpg',
    question:
      'Should AI be allowed to influence human decisions in healthcare?',
  },
  {
    id: 4,
    name: 'Question5',
    url: './img/dinesh.jpg',
    question:
      'Should AI be allowed to influence human decisions in healthcare?',
  },
  {
    id: 5,
    name: 'Question6',
    url: './img/dinesh.jpg',
    question:
      'Is it fair to use AI in law enforcement and criminal justice systems?',
  },
  {
    id: 6,
    name: 'Question7',
    url: './img/dinesh.jpg',
    question:
      'Should AI have the ability to autonomously learn and evolve without human oversight?',
  },
  {
    id: 7,
    name: 'Question8',
    url: './img/dinesh.jpg',
    question:
      'Can AI ever truly understand human emotions and ethical nuances?',
  },
]

function Advanced({ onAllCardsSwiped, sendArray, sendFinalArray }) {
  const [currentIndex, setCurrentIndex] = useState(dbs.length - 1)
  const [lastDirection, setLastDirection] = useState()
  const currentIndexRef = useRef(currentIndex)
  const [user, setUser] = useState(null)
  const [signShow, setSignShow] = useState(false)
  const [questionsData, setQuestionsData] = useState([])
  const [start, setStart] = useState(false)
  const [responses, setResponses] = useState([])
  const [indicate, setIndicate] = useState(false)
  const [cards, setCards] = useState(dbs);
  const location = useLocation()
  const navigate = useNavigate() // Initialize the navigate function

  useEffect(() => {
    // Example array to be sent
    sendArray(responses)
    sendFinalArray(questionsData)

    // Trigger onAllCardsSwiped when all cards are swiped
  }, [sendArray, responses, questionsData])

  ///////////////////////////////////////////////////////
  const fetchQuestionsData = async () => {
    try {
      const snapshot = await dbms.ref('questions').once('value')
      return snapshot.val()
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error
    }
  }

  const updateQuestionsData = async (updatedData) => {
    try {
      await dbms.ref('questions').set(updatedData)
      console.log('Data updated successfully!')
    } catch (error) {
      console.error('Error updating data:', error.message)
      throw error
    }
  }

  useEffect(() => {
    fetchQuestionsData()
      .then((questionsData) => {
        setQuestionsData(questionsData)
        console.log(questionsData[0].swipes.left)
        setStart(true)
      })
      .catch((error) => {
        console.error('Error fetching questions data:', error)
      })
  }, [])

  // Handle user authentication state
  // useEffect(() => {
  //   if (location.pathname === '/home' && indicate) {
  //     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //       if (currentUser) {
  //         let dummyArr = [...responses]
  //         let copyObj = [...questionsData]
  //         let i = 0
  //         for (let element of copyObj) {
  //           element.responses.push({
  //             [currentUser.displayName]: responses[i],
  //           }) // Example of adding {"nish": 0} to responses array
  //           ++i
  //         }
  //         console.log('Here are the updated ones')

  //         updateQuestionsData(copyObj)
  //         setUser(currentUser)
  //         toast.success(`Welcome back, ${currentUser.displayName}`, {
  //           position: 'top-center',
  //         })
  //       } else {
  //         setUser(null)
  //       }
  //     })
  //     return () => unsubscribe()
  //   }
  // }, [location.pathname, navigate, responses])

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     if (currentUser) {
  //       setUser(currentUser)
  //       toast.success(`Welcome back, ${currentUser.displayName}`, {
  //         position: 'top-center',
  //       })
  //       navigate('/card')
  //     } else {
  //       setUser(null)
  //     }
  //   })
  //   return () => unsubscribe()
  // }, [])

  // Handle user authentication state

  const childRefs = useMemo(
    () =>
      Array(dbs.length)
        .fill(0)
        .map(() => React.createRef()),
    [],
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < dbs.length - 1
  const canSwipe = currentIndex >= 0

  const swiped = (direction, nameToDelete, index) => {
    let copyObj = [...questionsData]
    console.log('this is copyobj')
    console.log(copyObj[0])
    let newNumber = 0
    if (direction == 'left') {
      let extraLeft = copyObj[index].swipes.left;
      ++extraLeft;
      copyObj[index].swipes.left = extraLeft;
      setQuestionsData(copyObj);
      newNumber = 0;
      setCards((prevCards) => prevCards.filter((card) => card.id !== index));
    } else if (direction == 'right') {
      let extraRight = copyObj[index].swipes.right
      ++extraRight
      copyObj[index].swipes.right = extraRight
      setQuestionsData(copyObj)
      newNumber = 1
      setCards((prevCards) => prevCards.filter((card) => card.id !== index));
    } else if (direction == 'up') {
      let extraUp = copyObj[index].swipes.up
      ++extraUp
      copyObj[index].swipes.up = extraUp
      setQuestionsData(copyObj)
      newNumber = 2
    }

    updateQuestionsData(copyObj)
    ////////////////////////////////////////////
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses, newNumber]
      console.log('Updated responses:', updatedResponses)
      return updatedResponses
    })

    /////////////////////////////////
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
    if (index - 1 < 0) {
      setSignShow(true)
      setIndicate(true)
      console.log('All cards are swiped')
      setTimeout(() => {
        if (onAllCardsSwiped) {
          onAllCardsSwiped() // Notify the parent component after 1 second
        }
      }, 1000) // 1-second delay
    }
  }

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    if (currentIndexRef.current >= idx) {
      childRefs[idx].current.restoreCard()
    }
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < dbs.length) {
      await childRefs[currentIndex].current.swipe(dir)
    }
  }

  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css?family=Damion&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
        rel="stylesheet"
      />

      {start && !signShow && (
        // <div className="cardContainer">
        //   {dbs.map((character, index) => (
        //     <TinderCard
        //       ref={childRefs[index]}
        //       className="swipe"
        //       preventSwipe={['down']}
        //       key={character.name}
        //       onSwipe={(dir) => swiped(dir, character.name, index)}
        //       onCardLeftScreen={() => outOfFrame(character.name, index)}
        //     >
        //       <div
        //         style={{
        //           backgroundImage: `linear-gradient(to right, #888888, #eeeeee), url(${character.url})`,
        //         }}
        //         className="card"
        //       >
        //         <div className="question">
        //           <p>{character.question}</p>
        //         </div>
        //         <div className="newText">
        //           <p>←Swipe→</p>
        //         </div>
        //         <div className="answerOptions">
        //           <div
        //             className={`pressable noOption`}
        //             onClick={() => swipe('left')}
        //           >
        //             No
        //           </div>
        //           <div
        //             className={`pressable yesOption`}
        //             onClick={() => swipe('right')}
        //           >
        //             Yes
        //           </div>
        //         </div>
        //       </div>
        //     </TinderCard>
        //   ))}
        // </div>
        <div className='cardContainer'>
          {cards.map((character, index) => (
            <SwipeCard 
              reference={childRefs[index]}
              swipe={swipe}
              key={character.name}
              name={character.name}
              index={index}
              question={character.question}
              url={character.url}
              swiped={swiped}
              // onSwipe={(dir) => swiped(dir, character.name, index)}
              // onCardLeftScreen={() => outOfFrame(character.name, index)}
            />
          ))
          }
        </div >
      )}

      {/* {signShow && (
        <div>
          {user ? (
            <div>
              <div className="final-text">
                Do you want to see the views of others?
              </div>
              <button
                className="centered-button"
                onClick={() => navigate('/card')}
              >
                Let's Go
              </button>
            </div>
          ) : (
            <div>
              <div className="final-text">
                Subscribe to Sanctity's Newsletter
              </div>
              <SignInwithGoogle />
            </div>
          )}
        </div>
      )} */}
    </div >
  )
}

export default Advanced

// {signShow && (
//   <div>
//     {user ? (
//       <div>
//         <div className="final-text">
//           Do you want to see the views of others?
//         </div>
//         <button
//           className="centered-button"
//           onClick={() => navigate('/card')}
//         >
//           Let's Go
//         </button>
//       </div>
//     ) : (
//       <div>
//         <div className="final-text">
//           Subscribe to Sanctity's Newsletter
//         </div>
//         <SignInwithGoogle />
//       </div>
//     )}
//   </div>
// )}
