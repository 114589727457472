import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from './utils/axios';

// Create a context with default values
const TokenContext = createContext({
  tokens: 0,
  setTokens: () => {},
  userInfo: null,
  setUserInfo: () => {},
});

// Provider component
export const TokenProvider = ({ children }) => {
  const [tokens, setTokens] = useState(0);
  const [userInfo, setUserInfo] = useState({
    isLoggedIn: false,
    name: null,
    email: null,
    id:null
  });

  // Check the user's login state on mount
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const response = await axios.get('/auth/checkLogin', { withCredentials: true });
        console.log(response);
        setUserInfo({
          isLoggedIn: true,
          name: response.data.name,
          email: response.data.email,
          id:response.data._id
        });
      } catch (error) {
        console.log('User is not logged in or session expired');
        setUserInfo({
          isLoggedIn: false,
          name: null,
          email: null,
          id:null
        });
      }
    };

    checkLogin();
  }, []);

  return (
    <TokenContext.Provider value={{ tokens, setTokens, userInfo, setUserInfo }}>
      {children}
    </TokenContext.Provider>
  );
};

// Custom hook to use the TokenContext
export const useTokens = () => useContext(TokenContext);
