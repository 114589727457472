import React from "react";
import axios from "../utils/axios";
import LoginForm from "./Loginform";
import { useTokens } from "../TokenContext";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";

const Login = () => {
  const { setUserInfo } = useTokens();
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post(
        "/auth/login",
        { email, password },
        { withCredentials: true }
      );

      console.log("User logged in:", response.data);
      setUserInfo({
        isLoggedIn: true,
        name: response.data.user.name,
        email: response.data.user.email,
        id:response.data.user._id,
      });
      navigate("/blogs");
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };

  return (
    <Stack
    spacing={2}
    sx={{
      height: "100vh", // Full viewport height
      justifyContent: "center", // Center content vertically
      alignItems: "center", // Center content horizontally
      textAlign: "center", // Center text alignment
      padding: 2, // Add some padding for smaller screens
    }}
  >
      {/* <Typography variant="h4">Login</Typography> */}
      {/* Login Form */}
      <LoginForm  onLogin={handleLogin}/>
      <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
        <Typography variant="body2">New User?</Typography>
        <Link to="/signup" component={RouterLink} variant="subtitle2">
          Create an account
        </Link>
      </Stack>
      
    </Stack>
    
  );
};

export default Login;
