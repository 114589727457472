import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Validation
    if (!email || !password) {
      setError('Please provide both email and password.');
      setLoading(false);
      return;
    }

    try {
      // Simulate an API call
      await onLogin(email, password);
      console.log('Sign In successful');
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while signing in.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{
        width: { xs: '90%', sm: 400 },
        margin: 'auto',
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 3,
        borderRadius: 4,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgb(0, 0, 0)",
      }}
    >
      <Typography variant="h5" textAlign="center" mb={2}>
        Sign In
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}

      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        type="email"
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black', },
          '& .MuiInputLabel-root.Mui-focused': { color: 'black', },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': { color: 'black', },
        }}
      />
      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        type="password"
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black', },
          '& .MuiInputLabel-root.Mui-focused': { color: 'black', },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': { color: 'black', },
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        sx={{ mt: 2, backgroundColor: 'black' }}
      >
        {loading ? 'Signing in...' : 'Sign in'}
      </Button>
    </Box>
  );
};

export default LoginForm;
