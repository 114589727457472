import React from 'react';
import axios from '../utils/axios'; 
import SignupForm from './SignUpform'; 
import { useTokens } from '../TokenContext';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";
const Signup = () => {
    const { setUserInfo } = useTokens();
    const navigate=useNavigate();
  const handleSignup = async (formdata) => {
    try {
        const response = await axios.post(
            '/auth/signup', 
            formdata, 
            { withCredentials: true } 
          );
          // setUserInfo({
          //   isLoggedIn: true,
          //   name: response.data.user.name,
          //   email: response.data.user.email,
          // });
      console.log('User signed up:', response.data);
      navigate('/login');
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };

  return (
    <Stack
    spacing={2}
    sx={{
      height: "100vh", // Full viewport height
      justifyContent: "center", // Center content vertically
      alignItems: "center", // Center content horizontally
      textAlign: "center", // Center text alignment
      padding: 2, // Add some padding for smaller screens
    }}
  >
    {/* <Typography variant="h4">Sign Up</Typography> */}
    <SignupForm onSignup={handleSignup} />
    <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
      <Typography variant="body2">Existing User?</Typography>
      <Link to="/login" component={RouterLink} variant="subtitle2">
        Log in 
      </Link>
    </Stack>
    {/* Login Form */}
  </Stack>
    
  );
};

export default Signup;
