import React from 'react'
import { motion, useTransform, useMotionValue, animate } from 'framer-motion'

const SwipeCard = ({ reference, question, url, name, index, swiped }) => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const x = useMotionValue(0);

    const opacity = useTransform(x, [-300, 0, 300], [0, 1, 0]);
    const opacityInMobile = useTransform(x, [-100, 0, 100], [0, 1, 0]);
    const rotate = useTransform(x, [-300, -50, 50, 300], [-15, 0, 0, 15]);
    const rotateInMobile = useTransform(x, [-100, 100], [-15, 15]);

    const handleDragEnd = () => {
        if (isMobile) {
            if (x.get() > 30) {
                animate(x, 100, { duration: 0.15 }).then(() => {
                    swiped('right', name, index);
                })
            } else if (x.get() < -30) {
                animate(x, -100, { duration: 0.15 }).then(() => {
                    swiped('left', name, index);
                })
            }
        } else {
            if (x.get() > 100) {
                animate(x, 300, { duration: 0.25 }).then(() => {
                    swiped('right', name, index);
                })
            } else if (x.get() < -100) {
                animate(x, -300, { duration: 0.25 }).then(() => {
                    swiped('left', name, index);
                })
            }
        }
    }

    const swipe = (direction) => {
        if (isMobile) {
            if (direction === 'left') {
                animate(x, -100, { duration: 0.15 }).then(() => {
                    swiped('left', name, index);
                });
            } else if (direction === 'right') {
                animate(x, 100, { duration: 0.15 }).then(() => {
                    swiped('right', name, index);
                });
            }
        } else {
            if (direction === 'left') {
                animate(x, -300, { duration: 0.75 }).then(() => {
                    swiped('left', name, index);
                });
            }
            else if (direction === 'right') {
                animate(x, 300, { duration: 0.75 }).then(() => {
                    swiped('right', name, index);
                });
            }
        }
    };

    return (
        <motion.div
            ref={reference}
            className='swipe'
            drag="x"
            dragTransition={{ bounceStiffness: 550, bounceDamping: 20 }}
            dragConstraints={{ left: 0, right: 0 }}
            style={{
                opacity: isMobile ? opacityInMobile : opacity,
                rotate: isMobile ? rotateInMobile : rotate,
                gridRow: 1,
                gridColumn: 1,
                x: x,
            }}
            onDragEnd={handleDragEnd}
        >
            <div
                style={{
                    backgroundImage: `linear-gradient(to right, #888888, #eeeeee), url(${url})`,
                }}
                className="card"
            >
                <div className="question">
                    <p>{question}</p>
                </div>
                <div className="newText">
                    <p>←Swipe→</p>
                </div>
                <div className="answerOptions">
                    <div
                        className={`pressable noOption`}
                        onClick={() => swipe('left')}
                    >
                        No
                    </div>
                    <div
                        className={`pressable yesOption`}
                        onClick={() => swipe('right')}
                    >
                        Yes
                    </div>
                </div>
            </div>
        </motion.div >
    )
}

export default SwipeCard