import React, { useEffect } from 'react';
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import './customComments.css';
import axios from './utils/axios';
import { useTokens } from './TokenContext';
import { useNavigate } from 'react-router-dom';

const Comments = ({ blogId, comments }) => {
  const { userInfo, setUserInfo } = useTokens();
  const navigate = useNavigate();

  console.log(userInfo);

  // Handle the add comment logic
  const handleAddComment = async (commentData) => {
    const newComment = {
      blogId,
      userId: commentData.userId,
      comId: commentData.comId,
      text: commentData.text,
      fullName: commentData.fullName,
      avatarUrl: commentData.avatarUrl,
      userProfile: commentData.userProfile,
    };

    try {
      const response = await axios.post('/comment/addComment', newComment);
      console.log(response);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleLoginRedirect = () => {
    alert('You need to log in to comment');
    navigate('/login');
  };

  const customNoComment = () => <div className="no-com">Be the first to comment!</div>;



  return (
    <div>
      <h3>Comments</h3>

      <CommentSection
        currentUser={
          userInfo.isLoggedIn
            ? {
                currentUserId: userInfo.email,
                currentUserImg: 'https://ui-avatars.com/api/?name=' + userInfo.name + '&background=F8FAFC',
                currentUserProfile: '/profile',
                currentUserFullName: userInfo.name,
              }
            : {
                currentUserId: 'guest',
                currentUserImg: 'https://ui-avatars.com/api/?name=Guest&background=F8FAFC',
                currentUserProfile: '/login',
                currentUserFullName: 'Guest',
              }
        }
        logIn={{
          onLogin: handleLoginRedirect,
          signUpLink: 'http://localhost:3001/',
        }}
        commentData={comments.map((comment) => ({
          userId: comment.userId,
          comId: comment.comId,
          avatarUrl: comment.avatarUrl,
          userProfile: comment.userProfile,
          fullName: comment.fullName,
          text: comment.text,
          replies: [],
          commentId: comment._id,
        }))}
        onSubmitAction={(commentData) => {
          if (userInfo.isLoggedIn) {
            handleAddComment(commentData);
          } else {
            handleLoginRedirect();
          }
        }}
      />
    </div>
  );
};

export default Comments;
